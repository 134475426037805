<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                广发证券携手非凸科技，探讨科技赋能量化
              </p>
              <div class="fst-italic mb-2">2023年7月17日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >广发证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/45/01-全景.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，广发证券携手非凸科技开展“科技而生，赋能量化”闭门沙龙活动，特别邀请到千衍私募基金，与在场嘉宾共同探讨如何为量化行业高质量发展蓄势赋能。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/45/02-王文欣.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    作为行业领先创新能力的资本市场综合服务商，拥有丰富的私募服务经验和成熟的私募业务，打造了私募基金全业务链、全生命周期服务体系，为私募基金提供专业交易、投研支持、种子基金、场外衍生品等一体化综合服务。广发证券机构客户部王文欣表示，“目前已构建‘观察库—白名单库—投资库—核心库’四级管理人策略池，通过种子基金引导机构资金跟投，陪伴优质私募快速成长。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/45/03-黄菁莹.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，ETF行业竞争越来越激烈，应用场景越来越丰富，客户认知度和使用率也逐渐增强。围绕ETF产品主题鲜明、交易便捷、成本低廉等特点，广发证券成功托管全市场第一只券商结算模式的ETF产品。广发证券资产托管部市场总监黄菁莹表示，“持续将指数投资产品运营作为战略发展业务，并打造‘跨区域、多模式、强运营’的指数产品服务体系，围绕交易、清算、资金、估值等一系列产品需求，为ETF提供便捷高效的综合金融服务方案，与合作伙伴共同打造ETF生态圈。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/45/04-周志辉.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    不仅如此，广发证券还将持续用技术和资源开创场外衍生品新局面，以满足客户多样化的投资需求。广发证券股权衍生品业务部互换交易主管周志辉分享了“场外衍生品为量化赋能”的主题演讲，对DMA业务、场外借券收益互换业务、股票银行系统、北上跨境业务等进行了详细介绍。周志辉表示，“随着场外衍生品不断发展，公司将致力于提供多空一体化、便捷化的立体式衍生品服务，最大化满足客户的风险对冲需求。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/45/05-郑媛姿.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技CBO郑媛姿就“科技而生，多维赋能”主题进行了分享。近年来，量化交易蓬勃发展，整体业绩给投资者带来了深刻改变。在马太效应下，国内算法市场可能将由头部算法公司为主，向各个类型的客户群体，提供更为标准化的交易执行全生命周期服务产品，以优质的产品、先进的技术、统一的规范、持续的投入构建不易逾越的护城河。郑媛姿表示，“在科技赋能下，科技创新与金融服务实现了深度融合。非凸科技将持续以优异绩效表现、Rust全栈技术、AI算法力、自建算力集群等为核心驱动，在保持自身高速发展的同时，通过定向资源引荐及友好交流平台，不断壮大开放创新、合作共赢的量化生态圈。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/45/06-马双杰.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    最后，千衍私募基金合伙人马双杰从因子体系、组合构建、策略亮点等方面对投资策略进行了分享。他表示，“产品绩效一致性，收益逻辑性，整体淡化组合的效用，使得最终持仓表现能反应整体因子的绩效，避免了因组合层面拟合而导致的风格漂移问题；除此之外，通过多个差异化的子策略组合等权配置，有效控制了模型的波动率及超额回撤。”
                  </p>
                  <p>
                    目前，广发证券与非凸科技已达成深度战略合作，本月即将上线非凸智能算法。欢迎各位管理人在广发证券申请试用，并提出宝贵建议。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News45",
};
</script>

<style></style>
